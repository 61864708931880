import React, {useEffect, useState} from 'react';
import './Services.css';
import {SERVICES_LIST, TServiceType} from "./services.config";
import {useWindowWidth} from "@react-hook/window-size";
import {ArrowDownSvg} from "../../ui/arrow-down.svg";

export const ServicesSection = () => {
	const width = useWindowWidth();
	const [defaultShowCards, setDefaultShowCards] = useState<TServiceType[]>([]);
	const [isShowAll, setIsShowAll] = useState(false);

	const handleShow = () => {
		if (isShowAll) {
			displayCardByWidth(width);
		} else {
			setDefaultShowCards(SERVICES_LIST);
		}

		setIsShowAll(!isShowAll);
	}

	const displayCardByWidth = (width: number) => {
		if(width > 1440) {
			setDefaultShowCards(SERVICES_LIST.slice(0,5));
		}

		if(width < 1440) {
			setDefaultShowCards(SERVICES_LIST.slice(0,4));
		}

		if(width < 1024) {
			setDefaultShowCards(SERVICES_LIST.slice(0,6));
		}

		if(width < 768) {
			setDefaultShowCards(SERVICES_LIST.slice(0,4));
		}
	}

	useEffect(() => {
		displayCardByWidth(width);
	},[width])

	return (
		<div className="services-section">
			<div className="wrapper services-content">
				<h2 className="services-header" data-aos="fade-right" data-aos-delay="1000">Наши услуги</h2>

				<div className="services-card-list">
					{defaultShowCards.map((service) => (
						<div
							key={service.title}
							data-aos="flip-left"
						>
							<div className="services-card-item">
								<h3>{service.title}</h3>
								<p>{service.description}</p>
							</div>
						</div>
					))}
				</div>

				<div className="btn-wrapper">
					<button className="show-more" onClick={handleShow}>
						{`Показать ${isShowAll ? 'меньше' : 'больше'}`}
						<i className={`btn-arrow ${isShowAll && 'arrow-up'}`}>
							<ArrowDownSvg />
						</i>
					</button>
				</div>
			</div>
		</div>
	);
};
