import React, {useEffect, useState} from 'react';
import {CloseIconSvg} from "../../ui/close.icon.svg";
import {TelIcon} from "../../ui/tel.icon";
import {LogoSvg} from "../../ui/logo.svg";
import {BurgerOpenSvg} from "../../ui/burger-open.svg";
import {LogoWhiteSvg} from "../../ui/logo-white.svg";

export const Burger = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isAnimating, setIsAnimating] = useState(false);

	useEffect(() => {
		if (isAnimating && !isOpen) {
			const timer = setTimeout(() => {
				setIsAnimating(false);
			}, 1400);
			return () => clearTimeout(timer);
		}
	}, [isOpen, isAnimating]);

	const handleClose = () => {
		if(!isOpen) {
			setIsAnimating(true);
		}
		setIsOpen(!isOpen);
	};

	const burgerStyle = () => {
		if(isAnimating && isOpen) {
			return {display: 'block'}
		} else if(!isAnimating && !isOpen) {
			return {display: 'none'}
		} else {
			return ;
		}
	}

	return (
		<div className="wrapper close-burger-wrapper">
			<div className="burger-wrapper">
				<div className="burger-header">
					{!isOpen
						?
						<>
							<LogoSvg />

							<div onClick={handleClose}>
								<BurgerOpenSvg />
							</div>
						</>
						: null
					}
				</div>


				<div className={`burger-content burger-bg ${isOpen ? 'open' : 'closed'}`} style={burgerStyle()}>
					<div className="wrapper">
						<div className="burger-header">
							<LogoWhiteSvg />

							<div onClick={handleClose}>
								<CloseIconSvg />
							</div>
						</div>
						<ul className="header-list" data-aos="fade" data-aos-delay="600">
							<li className="tel-item">
								<TelIcon />
								<a href="tel:+78124164116">416-41-16</a>
							</li>
							<li>
								<a href="mailto:info@partner-s.pro">info@partner-s.pro</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Burger;