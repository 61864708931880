export type TServiceType = {
	title: string;
	description: string;
}

export const SERVICES_LIST:TServiceType[] = [
	{
		title: 'РАСЦО',
		description: 'Присоединение к  Региональной автоматизированной системе централизованного оповещения'
	},
	{
		title: 'ПРВ',
		description: 'Проводное радиовещание'
	},
	{
		title: 'ДИС',
		description: 'Диспетчеризация инженерного оборудования'
	},
	{
		title: 'АСКУЭ',
		description: 'Автоматизированная система коммерческого учета энергоресурсов'
	},
	{
		title: 'СОТ',
		description: 'Система охранного телевидения'
	},
	{
		title: 'СКУД и ПТ СКУД',
		description: 'Система контроля и управления доступом, \n' +
			'в том числе прилегающей территории'
	},
	{
		title: 'ДМФ',
		description: 'Системы домофонной связи'
	},
	{
		title: 'СГ',
		description: 'Система газоанализа'
	},
	{
		title: 'СКС',
		description: 'Структурированная кабельная система'
	},
	{
		title: 'НСС',
		description: 'Наружные \n' +
			'сети связи'
	},
	{
		title: 'КСОБ',
		description: 'Комплексные системы обеспечения безопасности',
	},
	{
		title: 'АППЗ',
		description: 'Автоматизация противопожарной защиты',
	},
	{
		title: 'ЕМТС',
		description: 'Строительство единой мультисервисной телекоммуникационной сети',
	},
]