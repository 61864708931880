import React from 'react';
import './Welome.css';
import {WelcomeBgSvg} from "../../ui/welcomeBg.svg";
import {HeroBlock} from "./hero/Hero.block";

export const WelcomeSection = () => {
	return (
		<div id="welcome" className="welcome-section">
			<WelcomeBgSvg />
			<div className="wrapper section-content">
				<div className="welcome-text-block">
					<p className="welcome-title">
						<b data-aos="fade-left">Надежный</b>
						<br/>
						<span data-aos="fade-left" data-aos-delay="500">Исполнитель</span>
						<br/>
						<span data-aos="fade-left" data-aos-delay="1000">под ваши задачи</span>
					</p>

					<p className="welcome-subtitle">
						Комплексный подход по созданию
						<br/>
						слаботочных систем
					</p>
				</div>
				<HeroBlock />
			</div>
		</div>
	);
};
