import React from 'react';

export const WelcomeBgSvg = () => {
	return (
		<svg className="waves-bg" width="4961" height="1458" viewBox="0 0 4961 1458" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4535.49 42.9535C3691.9 328.119 3404.03 642.603 3299.7 881.177C3183.28 1147.44 3243.32 1438.45 3117.64 1456.52C3037.16 1468.1 2938.14 1359.43 2753.53 1121.22C2193.69 398.878 2181.01 207.051 1942.49 157.264C1608.36 87.4688 1402.38 415.821 888.776 408.743C511.55 403.533 206.3 220.711 0.560875 58.2026" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4555.75 71.6205C3735.91 350.646 3431.5 656.333 3318.9 879.927C3193.07 1129.8 3252.5 1405.78 3128.13 1418.83C3048.17 1427.23 2951.01 1320.42 2772.68 1090.37C2230.73 391.367 2213.92 197.151 1977.24 149.822C1641.17 83.561 1444.17 435.665 925.116 440.459C913.799 440.563 902.658 440.563 891.505 440.354C523.232 433.277 213.631 250.369 3.49093 88.8584" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4575.94 100.287C3779.92 373.147 3459.21 669.515 3338.05 878.659C3203 1111.78 3261.59 1373.07 3138.56 1381.12C3059.09 1386.33 2963.78 1281.37 2791.79 1059.5C2267.85 383.863 2247 186.635 2011.89 142.38C1674.18 78.8108 1485.76 455.682 961.364 472.182C949.859 472.547 938.392 472.912 926.924 472.868C552.565 471.444 226.868 284.758 6.35493 119.522" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4596.18 128.954C3823.86 395.726 3487.36 682.064 3357.23 877.391C3213.34 1093.38 3270.66 1340.34 3149.04 1343.41C3070.02 1345.4 2976.67 1242.34 2810.93 1028.64C2304.93 376.291 2279.96 176.466 2046.57 134.937C1707.07 74.5295 1527.32 475.847 997.646 503.906C985.889 504.531 974.145 505.131 962.438 505.391C582.019 513.78 240.202 319.157 9.31502 150.204" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4616.41 157.621C3867.84 418.227 3516.07 693.944 3376.42 876.123C3224.27 1074.6 3279.67 1307.58 3159.5 1305.69C3080.92 1304.47 2989.51 1203.29 2830.06 997.764C2342.02 368.753 2312.91 166.322 2081.25 127.504C1739.85 70.2915 1568.83 496.16 1033.92 535.664C1021.91 536.533 1009.89 537.401 997.835 537.94C611.38 553.936 253.443 353.572 12.1447 180.868" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4636.64 186.288C3911.81 440.737 3545.5 705.103 3395.57 874.846C3236.03 1055.48 3288.69 1274.78 3169.94 1267.98C3091.78 1263.5 3002.34 1164.26 2849.17 966.891C2379.06 361.215 2345.8 156.214 2115.91 120.061C1772.63 66.0883 1610.3 516.611 1070.17 567.362C1057.9 568.517 1045.63 569.689 1033.26 570.436C640.778 593.971 266.646 387.945 15.011 211.506" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4656.86 214.955C3955.8 463.273 3575.89 715.551 3414.77 873.579C3249 1036.17 3297.63 1241.95 3180.42 1230.27C3102.65 1222.52 3015.2 1125.19 2868.33 936.027C2416.16 353.686 2378.72 146.132 2150.61 112.628C1805.38 61.9203 1651.8 537.185 1106.47 599.086C1093.9 600.51 1081.4 601.969 1068.75 602.951C670.276 633.971 279.96 422.318 17.9519 242.171" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4677.09 243.621C3999.78 485.799 3644.75 750.782 3433.94 872.31C3306.26 1036.5 3306.58 1209.07 3190.88 1192.56C3113.5 1181.51 3028.04 1086.18 2887.46 905.111C2453.24 346.104 2411.61 136.04 2185.29 105.141C1838.13 57.7511 1693.3 557.835 1142.76 630.766C1129.96 632.459 1117.15 634.196 1104.19 635.429C699.686 673.857 293.15 456.689 20.8059 272.791" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4697.32 272.288C4043.75 508.327 3679.13 766.25 3453.12 871.043C3323.83 1020.66 3315.53 1176.15 3201.34 1154.85C3124.34 1140.48 3040.89 1047.16 2906.59 874.282C2490.39 338.61 2444.48 126.062 2219.95 97.743C1870.84 53.7136 1734.8 578.687 1179.04 662.533C1165.99 664.505 1152.91 666.519 1139.63 667.996C729.109 713.771 306.465 491.123 23.697 303.499" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4717.55 300.955C4087.74 530.854 3713.52 781.699 3472.3 869.775C3341.41 1004.82 3324.48 1143.19 3211.81 1117.14C3135.19 1099.43 3053.72 1008.12 2925.73 843.418C2527.4 331.046 2477.33 116.084 2254.63 90.3173C1903.54 49.6836 1776.35 599.598 1215.31 694.274C1202.01 696.514 1188.66 698.798 1175.08 700.527C758.531 753.605 319.73 525.53 26.5883 334.172" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4737.77 329.622C4131.7 553.381 3747.9 797.157 3491.48 868.498C3358.98 988.958 3333.45 1110.18 3222.28 1079.42C3146.06 1058.34 3066.56 969.062 2944.86 812.571C2564.49 323.56 2510.18 106.149 2289.32 82.8924C1936.23 45.715 1817.92 620.614 1251.59 726.015C1238.03 728.542 1224.41 731.078 1210.54 733.067C787.934 793.396 333 559.937 29.4841 364.853" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4758.01 358.297C4175.71 575.899 3782.25 812.615 3510.65 867.195C3376.53 973.074 3342.43 1077.12 3232.76 1041.67C3156.95 1017.18 3079.42 929.991 2964.01 781.638C2601.58 315.961 2543.04 96.1706 2323.98 75.3978C1968.89 41.7114 1859.53 641.612 1287.87 757.678C1274.04 760.483 1260.21 763.288 1245.99 765.494C817.319 833.04 346.265 594.248 32.3757 395.43" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4778.24 386.964C4219.68 598.426 3816.68 828.073 3529.83 865.962C3394.11 957.26 3351.47 1044.09 3243.21 1004C3167.84 976.088 3092.25 890.982 2983.13 750.8C2638.6 308.458 2575.86 86.314 2358.68 67.9902C2001.57 37.8644 1901.21 662.82 1324.15 789.436C1310.08 792.528 1295.92 795.585 1281.39 798.077C846.642 872.762 359.493 628.734 35.23 426.147" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4798.43 415.631C4263.66 620.953 3851.06 843.522 3549.01 864.677C3411.68 941.394 3360.53 1011 3253.66 966.283C3178.75 934.933 3105.09 851.946 3002.26 719.936C2675.72 300.92 2608.68 76.4316 2393.35 60.5481C2034.21 34.0263 1942.93 684.079 1360.43 821.16C1346.1 824.538 1331.66 827.856 1316.88 830.6C876.025 912.441 372.793 663.098 38.0811 456.811" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4818.69 444.297C4307.65 643.48 3885.44 858.98 3568.18 863.418C3429.25 925.554 3369.62 977.903 3264.13 928.55C3189.7 893.752 3117.93 812.893 3021.45 689.046C2712.85 293.365 2641.57 66.6093 2428.08 53.0966C2066.91 30.2309 1984.75 705.286 1396.76 852.875C1382.17 856.531 1367.46 860.1 1352.39 863.105C905.386 952.049 386.11 697.47 41.0872 487.458" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4838.92 472.964C4351.63 666.007 3919.83 874.438 3587.37 862.15C3446.82 909.705 3378.77 944.789 3274.59 890.86C3200.68 852.597 3130.78 773.865 3040.52 658.191C2749.88 285.853 2674.35 56.8309 2462.7 45.6716C2099.51 26.5227 2026.53 726.658 1432.99 884.572C1418.15 888.515 1403.16 892.336 1387.72 895.593C934.634 991.658 399.326 731.885 43.9289 518.174" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4859.15 501.631C4395.6 688.525 3954.22 889.861 3606.55 860.882C3464.46 893.882 3387.94 911.659 3285.05 853.144C3211.69 811.416 3143.61 734.829 3059.66 627.353C2786.96 278.349 2707.18 47.0957 2497.38 38.2724C2132.16 22.9012 2068.41 748.073 1469.26 916.374C1454.17 920.612 1438.91 924.676 1423.23 928.193C963.905 1031.22 412.552 766.275 46.8197 548.803" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4879.38 530.333C4439.59 711.087 3988.61 905.38 3625.73 859.64C3481.97 878.042 3397.16 878.572 3295.54 815.472C3222.73 770.314 3156.47 695.829 3078.81 596.489C2824.05 270.829 2740.02 37.3607 2532.07 30.8301C2164.81 19.2626 2110.34 769.462 1505.54 948.046C1490.21 952.579 1474.66 956.886 1458.69 960.664C993.14 1070.75 425.856 800.656 49.7114 579.424" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4899.61 558.991C4483.58 733.545 4027.77 811.764 3644.9 858.338C3501.63 875.767 3406.4 845.407 3305.99 777.722C3233.78 729.09 3169.3 656.75 3097.93 565.582C2861.13 263.239 2772.86 27.6174 2566.74 23.3534C2197.49 15.7199 2152.33 790.93 1541.82 979.796C1526.25 984.607 1510.38 989.166 1494.14 993.204C1022.37 1110.29 439.165 835.064 52.5966 610.132" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4919.84 587.648C4527.52 756.123 4066.05 911.815 3664.08 857.087C3519.07 837.339 3415.67 812.302 3316.45 740.023C3244.85 687.917 3182.15 617.731 3117.06 534.726C2898.2 255.718 2805.71 17.9598 2601.42 15.9364C2230.18 12.2542 2194.38 812.441 1578.11 1011.54C1562.28 1016.64 1546.13 1021.42 1529.58 1025.74C1051.21 1149.15 452.392 869.461 55.4498 640.796" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4940.07 616.307C4571.53 778.642 4097.33 939.57 3683.25 855.81C3536.37 826.093 3424.98 779.172 3326.91 702.307C3255.96 646.728 3194.98 578.678 3136.2 503.854C2935.28 248.172 2838.57 7.6955 2636.1 8.48575C2262.89 9.94467 2236.92 834.569 1614.38 1043.25C1598.33 1048.67 1581.88 1053.67 1565.04 1058.25C1080.53 1188.96 465.657 903.86 58.3796 671.469" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
			<path d="M4960.3 644.965C4615.51 801.152 4126.16 967.152 3702.44 854.525C3063.67 684.713 3008.41 -2.95153 2670.77 1.04321C2285.87 5.59373 2278 900.204 1600.49 1090.76C1109.87 1228.76 478.871 938.25 61.2702 702.116" stroke="#23CA50" strokeOpacity="0.2" strokeMiterlimit="10"/>
		</svg>

	);
};
