import React, {useLayoutEffect, useState} from 'react'
import './Header.css';
import {LogoSvg} from "../ui/logo.svg";
import {TelIcon} from "../ui/tel.icon";
import {useWindowWidth} from "@react-hook/window-size";
import {Burger} from "./burger/burger";

export const Header = () => {
	const width = useWindowWidth();

	const [isMobile, setIsMobile] = useState<boolean>();

	useLayoutEffect(() => {
		if(width < 768) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	},[width])

	return (
		<>
			{isMobile
				?
				<Burger />
				:
				<div className="wrapper header">
					<ul className="header-list">
						<li>
							<LogoSvg />
						</li>
						<li className="tel-item">
							<TelIcon />
							<a href="tel:+78124164116">416-41-16</a>
						</li>
						<li>
							<a href="mailto:info@partner-s.pro">info@partner-s.pro</a>
						</li>
					</ul>
				</div>
			}
		</>
	);
};
