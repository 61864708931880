import React, {useRef, useState} from 'react';
import './ContactUsForm.css';
import emailjs from '@emailjs/browser';
import {toast} from "react-toastify";
interface FormState {
	from_name: string;
	from_tel: string;
	from_email: string;
	message: string;
}

export const ContactForm: React.FC = () => {
	const form = useRef<HTMLFormElement | null>(null);
	const [formState, setFormState] = useState<FormState>({
		from_name: '',
		from_tel: '',
		from_email: '',
		message: '',
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setFormState({
			...formState,
			[event.target.name]: event.target.value,
		});
	};

	const handleSubmit = async (event: React.FormEvent) => {

		if (!!form.current) {
			event.preventDefault();
			emailjs.sendForm(
				process.env.REACT_APP_EMAIL_JS_SERVICE_ID as string,
				process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID as string,
				form.current,
				process.env.REACT_APP_EMAIL_JS_API_KEY as string,
			)
				.then(() => {
					toast.success('Форма успешно отправлена');
				}, (error) => {
					console.log('e', error);
				});
		}
	};

	return (
		<div className="form-wrapper">
			<h2 className="form-title" data-aos="fade-right">Свяжитесь с нами</h2>
			<form className="form" ref={form} onSubmit={handleSubmit}>
				<input
					type="text"
					name="from_name"
					value={formState.from_name}
					onChange={handleChange}
					placeholder="Имя"
				/>
				<input
					type="tel"
					name="from_tel"
					value={formState.from_tel}
					onChange={handleChange}
					placeholder="Номер телефона"
				/>
				<input
					type="email"
					name="from_email"
					value={formState.from_email}
					onChange={handleChange}
					placeholder="Email"
				/>
				<textarea
					name="message"
					value={formState.message}
					onChange={handleChange}
					placeholder="Сообщение"
				/>

				<div className="form-submit-wrapper">
					<button type="submit">Отправить</button>
					<p>Отправляя свои данные, вы соглашаетесь
						на обработку персональных данных</p>
				</div>
			</form>
		</div>
	);
};
