import React, {useEffect} from 'react';
import './App.css';
import AOS from "aos";
import "aos/dist/aos.css";
import {Header} from "./header/Header";
import {Main} from "./main/Main";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="App">
      <Header />
      <Main />
      <ToastContainer/>
    </div>
  );
}

export default App;
