import React from 'react';
import './ContarctUs.css';
import {ContactForm} from "./contactUsForm/ContactUs.form";

export const ContactUsSection = () => {
	return (
		<div id="image-wrapper" className="wrapper contactUs-content">
			<ContactForm />
			<div className="image-wrapper" data-aos="fade-up" data-aos-anchor="#image-wrapper">
				<img src="/images/car-with-logo.webp" alt="Автомобиль с логотипом" />
			</div>
		</div>
	);
};

