import React from 'react';

export const TelIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
			<path d="M8.05623 3.09352C7.75251 2.33421 7.01709 1.8363 6.19928 1.8363H3.44802C2.40159 1.8363 1.55328 2.68464 1.55328 3.73108C1.55328 12.6258 8.76406 19.8363 17.6588 19.8363C18.7052 19.8363 19.5533 18.9881 19.5533 17.9416L19.5532 15.1903C19.5532 14.3725 19.0552 13.6372 18.2959 13.3334L15.6604 12.2792C14.9783 12.0064 14.2018 12.1293 13.6375 12.5996L12.9564 13.167C12.1617 13.8292 10.9933 13.7763 10.2618 13.0448L8.34465 11.1278C7.61321 10.3964 7.56051 9.22784 8.22272 8.43319L8.7901 7.75228C9.26039 7.18793 9.38331 6.41123 9.11048 5.72917L8.05623 3.09352Z" stroke="#595959" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
};

