import React from 'react';

export const ArrowDownSvg = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none">
			<path d="M9.06152 1.96008L5.06152 5.96008L1.06152 1.96008" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
};

