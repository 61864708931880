import React from 'react';
import {WelcomeSection} from "./welcome/Welcome.section";
import {ServicesSection} from "./services/Services.section";
import {ContactUsSection} from "./contactUs/ContactUs.section";

export const Main = () => {
	return (
		<main>
			<WelcomeSection />
			<ServicesSection />
			<ContactUsSection />
		</main>
	);
};

