import React from 'react';
import './Hero.css';

export const HeroBlock = () => {
	return (
		<div className="welcome-hero-block" data-aos="fade-up" data-aos-anchor="#welcome">
			<img src={"/images/hero1.webp"} alt={"Рабочий в каске"}/>
			<img src={"/images/hero2.webp"} alt={"Рабочий в каске"}/>
		</div>
	);
};
